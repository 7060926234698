.card-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 15%;
    padding: 2%;
}

.card-footer > div {
    margin: 0 1%;
    flex-basis: 18%;
}