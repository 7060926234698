/* Row Layout */
.leado-card-row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 0;
    justify-content: flex-start;
    width: 100%;
    padding: 2% 0;
}

.leado-card-row-left-container {
    flex-basis: 7%;
    flex-grow: 0;
    height: 100%;
    position: relative;
}

.leado-card-row-content {
    flex-basis: 90%;
    flex-grow: 0;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 0;
    justify-content: flex-start;
}

.row1 {
    height: 100%;
}

.row1_2 {
    height: 50%;
}

.row1_3 {
    height: 33%;
}

/* Column Layout */
.leado-card-column {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    height: 100%;
    padding: 2%;
}

.column1 {
    width: 100%;
}

.column1_2 {
    width: 50%;
}

.column1_3 {
    width: 33%;
}

.column1_4 {
    width: 25%;
}

.column2_5 {
    width: 40%;
}

.column3_5 {
    width: 60%;
}

/* Image Content */
.card-content img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}


/* Text Content */
.center {
    margin: 0 auto;
}