* {
  font-family: 'Lato', 'sans-serif';
}

body {
  margin: 0;
}

form {
  width: 100%;
  height: 100%;
}

textarea {
  width: 100%;
  height: 100%;
}

.pointer:hover {
  cursor: pointer;
}

button:hover {
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12);
}

.padding {
  padding: 1rem 2rem;
}