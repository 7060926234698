.tool-options {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
}

.tool-option {
    flex-basis: 18%;
    flex-grow: 0;
    line-height: 0;
    padding: 1%;
}

.tool-option.selected, .tool-option.selected:hover {
    background: skyblue;
}

.tool-option:hover {
    background: #ddd;
}

.tool-option-image {
    width: 100%;
}