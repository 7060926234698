.card-editor-wrapper {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
}

.card-editor {
    display: flex;
    justify-content: center;
    width: 100%;
}

.card-editor-left {
    flex-basis: 50%;
    display: flex;
    flex-flow: column;
}

.card-editor-buttons {
    margin: 2rem 0;
}

.card-editor-buttons > button {
    margin-right: 1rem;
}