.tabbed {
    justify-content: space-between;
}

.tabbed .nav-item {
    max-width: 300px;
}

.tabbed .nav-link,
.tabbed .nav-link:hover {
    font-size: 1.2rem;
    padding: 1rem 0;
}

.tabbed .tabbed-cards .nav-link {
    color: #275598;
}

.tabbed .tabbed-cards .nav-link:hover {
    border-color: #275598;
}

.tabbed .tabbed-pathways .nav-link {
    color: #154733;
}

.tabbed .nav-link.active {
    color: white !important;
}

.tabbed .tabbed-cards .nav-link.active {
    background-color: #275598;
}

.tabbed .tabbed-pathways .nav-link.active {
    background-color: #154733;
}