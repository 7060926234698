.app-loader {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    position: fixed;
}

.component-loader {
    background-color: rgba(255, 255, 255, 0.75);
    position: absolute;
}

.component-loader > div {
    height: 100%;
}

.component-loader > div > div {
    margin: auto;
}

/*
.component-loader > div {
    margin: auto;
}
*/