.image-input-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
}

.image-input-preview {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.image-upload-loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-upload-cancel, .image-upload-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}