div.DraftEditor-root {
    background: white;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}
.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: #bbb;
  pointer-events: none;
}

.title-text-editor-buttons {
  display: flex;
  justify-content: flex-end;
}

.title-text-editor-buttons button {
  z-index: 1;
}
