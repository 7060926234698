.search-cards-form-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px 15px;
}

.search-cards-form-item-first {
    grid-column: 1 / span 3;
}

.search-cards-form-item-last {
    grid-column: 3 / span 1;
}