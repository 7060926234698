.pathway-editor-left {
    float: left;
    width: 35%;
    min-width: 300px;
    max-width: 620px;
    position: sticky;
    top: 10px;
}

.pathway-editor-right {
    margin-left: 35%;
    padding: 0 4%;
}

.pathway-editor-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
}

.pathway-editor-header {
    display: flex;
    align-items: center;
}

.remove-card-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
}