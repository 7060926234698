.list-item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 4px solid;
}

.list-item.draggable {
    cursor: pointer;
}

.list-item-actions {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.list-item-title {
    width: 100%;
    overflow: hidden;
    text-align: center;
    flex-grow: 1;
}

.list-item-actions > span {
    display: flex;
    justify-content: center;
    flex-basis: 50%;
    margin-top: .75rem;
}

.list-item-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    background: rgba(255, 255, 255, .7)
}

.list-item-logo {
    width: 60%;
    text-align: center;
}

.list-item-logo > img {
    width: 100%;
}