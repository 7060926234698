
.editor-trigger {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    opacity: .3;
    z-index: 10;
}

.editor-trigger > button {
    cursor: pointer;
}

.editor-trigger:hover {
    background: rgba(0, 0, 0, 0.1);
    opacity: 1;
}

.editor-trigger.disabled {
    pointer-events: none;
    opacity: .5;
}