.level-indicator {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.star-selector:hover {
    cursor: pointer;
}