.grid-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
}

.grid-list-item-wrapper {
    flex-grow: 0;
    max-width: 200px;
    min-width: 150px;
}

.grid-list-item-aspect-ratio {
    height: 0px;
    padding-top: 141%;;
    width: 100%;
    position: relative;
}

.grid-list-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid-list-item > * {
    width: 100%;
    height: 100%;
}