.horizontal-list-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
}

.horizontal-list-frame {
    margin: 0;
    width: 80%;
    overflow-x: hidden;
    position: relative;
    user-select: none;
    margin: 0 2%;
}

.horizontal-list {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 1s;
}

.horizontal-list-item-wrapper {
    flex-shrink: 0;
    flex-grow: 0;
}

.horizontal-list-item-wrapper > * {
    width: 100%;
    height: 100%;
}

.horizontal-list-loader {
    background: rgba(255, 255, 255, .7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}