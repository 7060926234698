.card-content {
    position: relative;
    display: flex;
    height: 70%;
    flex-flow: column;
    padding: 2% 0;
}

.card-content p {
    margin: 0;
    padding: 0;
    letter-spacing: 0;
}

.content-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.content-wrapper > * {
    height: 100%;
}

.content-text {
    pointer-events: none;
    white-space: pre-wrap;
}

.content-text-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.content-text-overflow-warning {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.7rem !important;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(253, 236, 234, 0.7) !important;
    border-radius: 0 !important;
}

.content-text-overflow-warning .MuiAlert-icon {
    margin: 0;
}

.leado-copyright {
    position: absolute;
    right: -45px;
    bottom: 65px;
    transform: rotate(-90deg);
    font-size: 10px;
}

.leado-copyright.smaller {
    right: -33px;
    bottom: 44px;
    font-size: 7px;
}
