.templates-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 800px;
}

.template-thumbnail {
    flex-basis: 30%;
    flex-grow: 0;
    margin-bottom: 1rem;
}

.template-thumbnail.selected, .template-thumbnail.selected:hover {
    outline: 2px solid skyblue;
}

.template-thumbnail:hover {
    outline: 2px solid #ccc;
    cursor: pointer;
}

.template-thumbnail > img {
    width: 100%;
    height: auto;
}