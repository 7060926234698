.leado-card-header {
    align-items: center;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 15%;
}

.leado-card-header-competence {
    color: white;
    flex-basis: 13%;
    flex-grow: 0;
    height: 100%;
}

.leado-card-header-competence, .leado-card-header-metacognitive {
    display: flex;
    justify-content: center;
    align-items: center;
}

.leado-card-header-info {
    display: flex;
    flex-flow: column;
    flex-basis: 61%;
    justify-content: center;
    flex-grow: 0;
    height: 100%;
    padding: 2%;
}

.leado-card-header-title {
    z-index: 100;
}

.leado-card-header-title, .leado-card-header-subtitle {
    height: 50%;
    flex-grow: 0;
    position: relative;
}
.leado-card-header-title-text, .leado-card-header-subtitle-text {
    overflow: hidden;
}

.leado-card-header-student-group, .leado-card-header-metacognitive {
    flex-basis: 13%;
    flex-grow: 0;
    /* height: 100%; */
    position: relative;
}

.leado-card-header-student-group {
    line-height: 0;
}

.leado-card-header-student-group > img {
    width: 100%;
    height: auto;
}

.leado-card-header h2, .leado-card-header h5 {
    font-weight: bold;
    margin: 0;
}

.leado-card-header h5 {
    font-size: 120%;
}

textarea {
    width: 90%;
}