.nav-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.nav-wrapper > * {
    margin-left: 1rem;
}

.leado-navbar {
    padding: 0 !important;
    justify-content: space-between !important;
    background-size: 100%;
    background-repeat: repeat-y;
    background-image: url('../../../assets/images/juomuvaaka.png');
}

.leado-navbar-logo {
    width: 100%;
    vertical-align: unset;
}

.leado-navbar .navbar-brand {
    margin-left: 6%;
    padding: 0 !important;
    width: 12%;
    max-width: 140px;
    flex-grow: 0;
    align-self: flex-start;
}

.leado-navbar .nav-item {
    margin-right: 1rem;
    min-width: 120px;
    text-align: center;
}

.leado-navbar .nav-item.logout-item {
    min-width: unset;
}

.leado-navbar .nav-item.logout-item a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 40px;
    height: 40px;
    background-color: #b61f24;
    color: white !important;
}

.leado-navbar .nav-item.logout-item a:hover {
    background-color: rgb(150, 0, 0);
}

.leado-navbar .nav-item .nav-link {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.85);
    color: black !important;
}

.leado-navbar .nav-item .nav-link:hover {
    background-color: rgba(255, 255, 255, 1);
}

.leado-navbar .nav-item .nav-link.active {
    background-color: rgb(39, 85, 152);
    color: white !important;
}