.TeachersPage .search-form {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}

.TeachersPage .search-form > * {
    margin-bottom: 1rem !important;
    margin-right: 1rem;
}

.TeachersPage .search-form > div {
    flex-basis: 30%;
    min-width: 250px;
}