.leado-card-wrapper {
    margin: 0 auto;
    flex-basis: 50%;
}

.leado-card-aspect-ratio-box {
    height: 0;
    padding-top: 141.4%;
    position: relative;
    width: 100%;
}

.leado-card {
    bottom: 0;
    background: white;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.editable {
    position: relative;
    cursor: pointer;
    pointer-events: all;
}

.editable:hover {
    outline: 2px dashed rgb(201, 201, 147);
    outline-offset: -2px;
}