div.DraftEditor-root {
    background: white;
    border: 1px solid #ccc;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}
.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: #bbb;
  pointer-events: none;
}

.content-text-editor-buttons {
  top: 100%;
  right: 0;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: absolute;
  min-width: 180px;
  width: 100%;
  z-index: 10;
}

.content-text-editor-buttons > * {
    z-index: 1;
}
