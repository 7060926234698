.empty-list-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    outline: 2px dashed #ccc;
    outline-offset: -5px;
    justify-content: center;
    padding: 2%;
}