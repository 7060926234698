* {
    box-sizing: border-box;
}

form > div {
    margin-bottom: 1rem !important;
}

.grid-list-pagination {
    margin-left: auto;
}