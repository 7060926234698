.add-icon-wrapper {
    align-items: center;
    background-color: rgb(0, 83, 139);
    border-radius: 100%;
    color: white;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.add-icon {
    font-size: 20pt;
}

.content-creator {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    outline: 2px dashed #ccc;
    outline-offset: -5px;
    justify-content: center;
    padding: 2%;
}

.content-creator > button {
    cursor: pointer;
}

.content-creator:hover {
    background: rgba(1, 1, 1, .08);
}