.card-section-header {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.no-results {
    display: flex;
    align-items: center;
    min-height: 200px;
}

.add-item-trigger {
    width: 150px;
    min-width: 150px;
    height: 200px;
}

.user-item-dashboard {
    display: flex;
}